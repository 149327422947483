@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  scrollbar-width: 3px;
}

html,
body {
width: 100%;
/* height: auto; */
height: 100%;
margin: 0%;
padding: 0;
box-sizing: border-box;
overflow-x: hidden;
font-size: 14px;
font-weight: 400;
font-family: 'Source Sans 3', sans-serif;
background-color: #f4f6f9;
}

:root {
  /*      Theme colors        */
  --primary-color: #17a2b8;
}

/* navigation component */

.main-header .login-logo .brand-logo img {
  width: 100px;
}

.layout-top-nav .navbar-nav .nav-item a{
  color: rgba(255, 255, 255, .75);
}
.layout-top-nav .navbar-nav .nav-item a:hover{
  color: white;
}
.layout-top-nav .navbar-nav .nav-item a.active{
    color: white
}

/* login page */
.login-page .login-box {
  width: 420px;
}

.login-page .login-box .card, .login-page .login-box .card-body{
  border-radius: 15px;
}

.login-page .login-box .login-logo .brand-logo img {
  width: 200px;
}

/* video management page */

.layout-top-nav .main-header.navbar.sticky-top {
  box-shadow: 0 10px 30px rgba(0,0,0,.1);
  border: 0;
}

.layout-top-nav .card {
  border: none;
  border-radius: .85rem;
  box-shadow: 0 0 20px 0 rgba(76,87,125,.02);
}

.layout-top-nav .card-header.main-title h2{
    color: #212529;
    font-family: Dosis, sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: .05em;
    margin: 0;
    padding: 0;
    position: relative;
    text-transform: capitalize;
}

/* datatable */

.layout-top-nav .page-item.active .page-link {
  background-color: var(--primary-color);
  border-color:var(--primary-color);
}

.layout-top-nav .page-link {
color: var(--primary-color);
}

.layout-top-nav .page-link:hover {
  color: var(--primary-color);
}

.layout-top-nav .page-item.disabled .page-link {
  color: var(--primary-color);
}

.layout-top-nav .dropdown-item.active, .layout-top-nav .dropdown-item:active {
  background-color: white;
}

.product-table tr td, .product-table tr th {

  border-top: 1px dashed #E5EAEF;
  font-size: 0.9rem;
    line-height: 22px;
    word-wrap: break-word;
    white-space: normal;
    vertical-align: middle;
    padding: 0.75rem;
}