@media (max-width: 576px) {
    .login-page .login-box {
        margin-top: .5rem;
        width: 100%;
    }
}

/* datatable */
@media (max-width: 40em) {
    .responsiveTable tbody tr {
        border: 1px solid #E5EAEF !important;
    }
    .arrow-sort {
        display: none;
    }
}